import {
  FlexCenter,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  PageTitle,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { styled } from "linaria/react";
import React from "react";

const HeroContainer = styled(SectionContainer)`
  min-height: 690px;
  background: var(--blue-500);
  color: var(--white);
`;

const PartnerCaseStudyHero = ({ title, blurb, videoUuid }) => {
  return (
    <HeroContainer>
      <SectionInner>
        <SectionCol style={{ alignItems: "center" }}>
          <div
            className="wistia_responsive_padding"
            style={{
              padding: "56.25% 0 0 0",
              position: "relative",
              width: "100%",
            }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              <span
                className={`wistia_embed wistia_async_${videoUuid} popover=true popoverAnimateThumbnail=true videoFoam=true`}
                style={{
                  display: "inline-block",
                  height: "100%",
                  position: "relative",
                  width: "100%",
                }}
              >
                &nbsp;
              </span>
            </div>
          </div>
        </SectionCol>
        <FlexCenter>
          <TextContainer>
            <PreTitle>partner case study</PreTitle>
            <PageTitle>{title}</PageTitle>
            <MainParagraph>{blurb}</MainParagraph>
          </TextContainer>
        </FlexCenter>
      </SectionInner>
    </HeroContainer>
  );
};

export default PartnerCaseStudyHero;
