import { animated, useSpring } from "@react-spring/web";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { MainParagraph, Title } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useIsVisible } from "hooks/useIsVisible";
import { styled } from "linaria/react";
import React, { useEffect, useRef, useState } from "react";
import { toTitleCase } from "./util/helperFunctions";
import { css } from "linaria";

const AnimatedScrollSectionContainer = styled(SectionContainer)`
  overflow: unset;
  background-color: var(--nuetral-100);
  @media (min-width: 1800px) {
  }
`;

const ScrollSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  gap: 2rem;
`;

const TextColumn = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 10rem;
`;

const TextSection = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  @media (min-width: 1800px) {
    max-height: unset;
  }
`;

const StickySection = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  width: 800px;
  flex-shrink: 0;
  position: sticky;
  position: -webkit-sticky;
  top: calc((100dvh - 5rem) / 4);
  @media (min-width: 1800px) {
    max-height: unset;
  }
`;

const ResponsiveTitle = styled(Title)`
  font-size: 32px;
`;

const ScrollSectionTitle = styled(Title)`
  text-align: center;
  margin-bottom: 2.5rem;
  color: var(--blue-500);
`;

const fullWidth = css`
  width: 100%;
`;

const StickyScrollSection = ({ title, textSections }) => {
  const itemsRef = useRef([]);
  const [currentSection, setCurrentSection] = useState(textSections[0]);
  const [animate, setAnimate] = useState(false);
  const isVisible = useIsVisible(itemsRef, true);

  const [fadeIn, setFadeIn] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    []
  );

  useEffect(() => {
    if (animate) {
      setFadeIn({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 500 },
        delay: 0,
      });
    }
  }, [animate]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, textSections.length);
  }, []);

  useEffect(() => {
    if (!itemsRef) return;
    itemsRef.current.map((item, index) => {
      if (isVisible === item) {
        setCurrentSection(textSections[index]);
        setAnimate(true);
      } else {
        setAnimate(false);
      }
    });
  }, [isVisible, itemsRef]);

  return (
    <AnimatedScrollSectionContainer>
      <SectionInner
        style={{
          flexDirection: "column",
        }}
      >
        <ScrollSectionTitle>{title}</ScrollSectionTitle>
        <ScrollSection>
          <StickySection>
            <animated.div style={fadeIn} className={fullWidth}>
              <GatsbyImage
                image={getImage(currentSection.image)}
                alt=""
                style={{ width: "100%" }}
                objectFit="contain"
              />
            </animated.div>
          </StickySection>
          <TextColumn>
            {textSections.map((item, i) => (
              <TextSection>
                <TextContainer width="500px" style={{ gap: "1.25rem" }}>
                  <ResponsiveTitle ref={el => (itemsRef.current[i] = el)}>
                    {toTitleCase(item.title)}
                  </ResponsiveTitle>
                  {item.paragraphs.map(p => (
                    <MainParagraph>{p}</MainParagraph>
                  ))}
                </TextContainer>
              </TextSection>
            ))}
          </TextColumn>
        </ScrollSection>
      </SectionInner>
    </AnimatedScrollSectionContainer>
  );
};

export default StickyScrollSection;
