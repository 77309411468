import GlobalLayout from "components/page/GlobalLayout";
import PartnerCaseStudyTemplate from "components/partner-case-study/PartnerCaseStudyTemplate";
import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function lvc() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        title: "LVC & Rhombus: Succeeding Together ",
        blurb:
          "The partnership between LVC and Rhombus is more than just a vendor and integrator relationship. In just three short years, it has already proven to be extremely successful. Learn why, and how the two teams are set to continue their growth together.",
        videoUuid: "onefnitv7g",
      },
      featuredQuote: [
        {
          quote:
            "From the very start of an opportunity, Rhombus is engaged, always available to provide whatever we need to make our end users happy. We design the systems together—it's partnership through and through.",
          author: {
            name: "Josh Klinger",
            role: "National Sales Consultant for LVC",
            photo: data.josh,
          },
        },
      ],
      aboutSection: {
        paragraphs: [
          "LVC is an industry-leading systems integration company based out of Minneapolis, MN with a broad customer base across commercial, industrial, government, and institutional markets. Their experienced team has made a national footprint in low-voltage data networking and security infrastructure.",
          "Since 2020, Rhombus and LVC have been working together to provide modern, user-friendly physical security solutions to their shared clients. LVC was introduced to Rhombus through a vetting process they perform to identify industry leaders. According to Josh Klinger, National Sales Consultant for LVC, they were drawn to Rhombus’ state-of-the-art technology and feature-rich offerings.",
          "“Rhombus really rung the bell with their integration capabilities and clean, user-friendly platform,” says Klinger.",
        ],
        highlights: [
          {
            icon: data.time,
            title: ["In business since 1982", "Partner since 2020"],
          },
          {
            icon: data.trust,
            title: [
              "Serves commercial, industrial, real estate & property management, financial, healthcare, government and institutions",
            ],
          },
          { icon: data.ribbon, title: ["SSI Integrator of the Year"] },
          { icon: data.ribbon, title: ["SDM Integrator of the Year"] },
        ],
      },
      partnerQuotes: {
        title: "The LVC & Rhombus Partnership Experience",
        quotes: [
          {
            quote:
              "The Rhombus Team is always happy to jump through hoops, push opportunities forward, get cameras deployed quickly, and provide whatever we need to make our end users happy. A fantastic partner from start to finish.",
            author: {
              name: "Josh Klinger",
              role: "National Sales Consultant for LVC",
              photo: data.josh,
            },
          },
          {
            quote:
              "From the very first call with the customer, to the installation, the implementation of the software, and beyond, LVC is constantly involved and making sure that it’s a fantastic experience for everybody.",
            author: {
              name: "Phil Wendt",
              role: "Channel Account Manager at Rhombus for LVC.",
              photo: data.phil,
            },
          },
        ],
      },
      scrollSection: {
        title: "How LVC and Rhombus Create Value for End-Users",
        rows: [
          {
            image: data.valueImage1,
            title: "Trading High Maintenance Machines for Automatic Updates",
            paragraphs: [
              "LVC serves customers who are in need of improving their physical security operations but are battling the limitations of on-prem machines. On-premise solutions, such as NVRs, require regular maintenance and updates—which means administrative work, physical labor, and associated costs.",
              "By offering Rhombus products and services, LVC helps clients easily modernize security operations while saving time, money, and stress.",
            ],
          },
          {
            image: data.valueImage2,
            title: "Custom, Hands-On Project Management from Start to Finish",
            paragraphs: [
              "LVC and Rhombus work together to give clients a completely seamless and white-glove experience. They draw on both companies’ expertise to design custom, curated solutions for each organization.",
              "The partnership is ideal for clients who want to scale their security systems. As a cloud-based platform, Rhombus supports unlimited locations from one unified console. Meanwhile, the LVC team are experts at project managing deployments of all sizes across multiple states.",
            ],
          },
          {
            image: data.valueImage3,
            title: "Ease of Use and Comprehensive Training",
            paragraphs: [
              "“Ease of use is a huge deal for our end users. The Rhombus Console is clean and incredibly user-friendly, and our clients have gotten back massive amounts of time finding video footage within the platform,” says Klinger. When an incident occurs, users can locate footage and pass the video to the authorities with just a few clicks.",
              "To enhance the white-glove experience, the Rhombus team takes care of training all end users. According to Klinger, Rhombus reduces training time for clients, which is especially crucial as departments experience natural turnover. “Rhombus’ training and support are far and away better than anyone else in the industry.”",
            ],
          },
        ],
      },
    };

    return (
      <GlobalLayout>
        <Helmet>
          <title>LVC Case Study | Rhombus</title>
          <meta name="description" content="" />
          <script
            src={`https://fast.wistia.com/embed/medias/${pageData.headerSection.videoUuid}.jsonp`}
            async
          ></script>
          <script
            src="https://fast.wistia.com/assets/external/E-v1.js"
            async
          ></script>
        </Helmet>
        <PartnerCaseStudyTemplate
          partnerName="LVC"
          headerSection={pageData.headerSection}
          featuredQuote={pageData.featuredQuote}
          aboutSection={pageData.aboutSection}
          partnerQuotes={pageData.partnerQuotes}
          scrollSection={pageData.scrollSection}
        />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      time: file(relativePath: { eq: "components/common/icons/time.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      trust: file(relativePath: { eq: "components/common/icons/trust.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      ribbon: file(
        relativePath: { eq: "components/common/icons/certification-blue.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      valueImage1: file(
        relativePath: {
          eq: "components/partner-case-study/img/r400-install-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      valueImage2: file(
        relativePath: {
          eq: "components/partner-case-study/img/multi-location-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      valueImage3: file(
        relativePath: {
          eq: "components/partner-case-study/img/share-clip-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      josh: file(
        relativePath: {
          eq: "components/partner-case-study/img/josh-klinger.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      phil: file(
        relativePath: { eq: "components/partner-case-study/img/phil-w.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
