import { toTitleCase } from "components/common/util/helperFunctions";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  Title,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";

const quoteContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
`;

const QuoteCard = styled.div`
  border-radius: 1rem;
  background: var(--nuetral-100);
  padding: 1rem;
  max-width: 1161px;
  min-height: 300px;
  display: flex;
  align-items: center;
  gap: 2.5rem;
  .flex-row {
    display: flex;
    gap: 1.25rem;
  }
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const QuoteCol = styled.div`
  display: flex;
  width: 10%;
  height: 100%;
  align-items: ${props => (props.end ? "flex-end" : "flex-start")};
  justify-content: ${props => (props.end ? "flex-end" : "flex-start")};
  svg {
    transform: ${props => (props.end ? "rotate(180deg)" : "unset")};
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const QuoteInner = styled.div`
  display: flex;
  gap: 2.5rem;
`;

const AuthorPhoto = styled.div`
  width: 168px;
  height: 168px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 3px solid var(--blue-500);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .author-photo {
    width: 100%;
    height: 100%;
  }
`;

const AuthorPhotoMobile = styled(AuthorPhoto)`
  width: 49px;
  height: 49px;
  border: 1px solid var(--blue-500);
`;

const QuoteText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  flex-grow: 1;
  p {
    max-width: 734px;
  }
`;

const PartnerQuoteSection = ({ title = false, cards }) => {
  const { isMobile } = useIsMobile(820);
  const renderQuoteSVG = () => {
    if (isMobile) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="37"
          viewBox="0 0 48 37"
          fill="none"
        >
          <path
            d="M9.27881 0H19.4498L13.026 18.5869H19.2714V37H0V20.3239L9.27881 0ZM37.829 0H48L41.5762 18.5869H47.8216V37H28.5502V20.3239L37.829 0Z"
            fill="#2A7DE1"
          />
        </svg>
      );
    } else
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={"68"}
          height="53"
          viewBox="0 0 68 53"
          fill="none"
        >
          <path
            d="M13.145 0H27.5539L18.4535 26.6244H27.3011V53H0V29.1127L13.145 0ZM53.5911 0H68L58.8996 26.6244H67.7472V53H40.4461V29.1127L53.5911 0Z"
            fill="#2A7DE1"
          />
        </svg>
      );
  };

  return (
    <SectionContainer>
      <SectionInner
        style={{
          flexDirection: "column",
          alignItems: "center",
          gap: "5rem",
        }}
      >
        {title && (
          <Title
            color="var(--blue-500)"
            style={{
              textAlign: "center",
            }}
          >
            {toTitleCase(title)}
          </Title>
        )}
        <div className={quoteContainer}>
          {cards.map(card => (
            <QuoteCard>
              <QuoteCol>{renderQuoteSVG()}</QuoteCol>
              <QuoteInner>
                {!isMobile && (
                  <AuthorPhoto>
                    <GatsbyImage
                      image={getImage(card.author.photo)}
                      alt=""
                      className="author-photo"
                      objectFit="cover"
                    />
                  </AuthorPhoto>
                )}
                <QuoteText>
                  <MainParagraph>{card.quote}</MainParagraph>
                  <div className="flex-row">
                    {isMobile && (
                      <AuthorPhotoMobile>
                        <GatsbyImage
                          image={getImage(card.author.photo)}
                          alt=""
                          className="author-photo"
                          objectFit="cover"
                        />
                      </AuthorPhotoMobile>
                    )}
                    <div>
                      <TitleSmall>{card.author.name}</TitleSmall>
                      <span>{card.author.role}</span>
                    </div>
                  </div>
                </QuoteText>
              </QuoteInner>
              <QuoteCol end>{renderQuoteSVG()}</QuoteCol>
            </QuoteCard>
          ))}
        </div>
      </SectionInner>
    </SectionContainer>
  );
};

export default PartnerQuoteSection;
