import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  Title,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import React from "react";
import { styled } from "linaria/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { toTitleCase } from "./util/helperFunctions";

const TextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const StickyScrollSectionMobile = ({ title, textSections }) => {
  return (
    <SectionContainer>
      <SectionInner
        style={{ flexDirection: "column", alignItems: "center", gap: "5rem" }}
      >
        <Title style={{ textAlign: "center", color: "var(--blue-500)" }}>
          {title}
        </Title>
        {textSections.map(item => (
          <TextBox>
            <TextContainer width="600px">
              <GatsbyImage
                image={getImage(item.image)}
                alt=""
                style={{ marginBottom: "2.5rem" }}
              />
              <TitleMed>{toTitleCase(item.title)}</TitleMed>
              {item.paragraphs.map(p => (
                <MainParagraph>{p}</MainParagraph>
              ))}
            </TextContainer>
          </TextBox>
        ))}
      </SectionInner>
    </SectionContainer>
  );
};

export default StickyScrollSectionMobile;
