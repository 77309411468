import RhombusBlade from "components/common/RhombusBlade";
import StickyScrollSection from "components/common/StickyScrollSection";
import StickyScrollSectionMobile from "components/common/StickyScrollSectionMobile";
import useIsMobile from "hooks/useIsMobile";
import React from "react";

import AboutSection from "./AboutSection";
import PartnerCaseStudyHero from "./PartnerCaseStudyHero";
import PartnerQuoteSection from "./PartnerQuoteSection";

const PartnerCaseStudyTemplate = ({
  partnerName,
  headerSection,
  featuredQuote,
  aboutSection,
  partnerQuotes,
  scrollSection,
}) => {
  const { isMobile } = useIsMobile(1200);
  return (
    <>
      <PartnerCaseStudyHero
        title={headerSection.title}
        blurb={headerSection.blurb}
        videoUuid={headerSection.videoUuid}
      />
      <PartnerQuoteSection cards={featuredQuote} />
      <AboutSection
        partnerName={partnerName}
        paragraphs={aboutSection.paragraphs}
        highlights={aboutSection.highlights}
      />
      {isMobile ? (
        <StickyScrollSectionMobile
          title={scrollSection.title}
          textSections={scrollSection.rows}
        />
      ) : (
        <StickyScrollSection
          title={scrollSection.title}
          textSections={scrollSection.rows}
        />
      )}
      <PartnerQuoteSection
        cards={partnerQuotes.quotes}
        title={partnerQuotes.title}
      />
      <RhombusBlade
        fancy
        title="Becoming a Partner is Simple"
        blurb="Submit your application online in 5 minutes, and our Partner Team will reach out within two business days."
        button={{
          text: "Apply",
          path:
            "https://partners.rhombussystems.com/prm/English/c/Terms?__hstc=158651301.11b9a359e50e4a16823c8ed728407db3.1691694797463.1692043226007.1692120531447.10&__hssc=158651301.9.1692120531447&__hsfp=3529731725",
          useHref: true,
          target: "_blank",
        }}
      />
    </>
  );
};

export default PartnerCaseStudyTemplate;
